.publisher-app-section .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(265.74deg, #1179bf -0.24%, #012d6c 102.38%);
  border-radius: 32px;
}

.publisher-app-section .container .right-side {
  padding-right: 5rem;
  margin-top: -7rem;
}

.publisher-app-section .container .right-side img {
  width: 100%;
  height: 100%;
}

.publisher-app-section p {
  font-weight: 400;
  font-size: 1.125rem;
  color: var(--color-white);
}

.publisher-app-section p:not(:first-of-type) {
  margin-top: 1rem;
  width: 70%;
}

@media (max-width: 959px) {
  .publisher-app-section p:not(:first-of-type) {
    width: 100%;
  }
}

.publisher-app-section .left-side p:nth-child(2) {
  margin-top: 1.5rem;
  font-weight: 700;
  font-size: 2rem;
  color: var(--color-white);
}

.publisher-app-downloads {
  margin-top: 2rem;
  display: flex;
  justify-content: flex-start;
}

.publisher-app-download img {
  height: 3rem;
  width: 10rem;
}

.publisher-app-download:not(:first-of-type) {
  margin-left: 1rem;
}
